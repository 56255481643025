<template>
  <div class="aboutUs">
    <div class="aboutUs_banner">
      <img src="@/assets/img/AboutUs/banner-about.png" />
    </div>
    <div class="aboutUs_int">
      <div class="AboutIntroduction_content">
        <div class="homeScenes_title">
          <div>公司介绍</div>
          <div>Company introduction</div>
        </div>
        <div class="AboutIntroduction_img">
          <img src="@/assets/img/AboutUs/pic-company.png" />
        </div>
        <div class="companyInt">
          <!-- 广州冰眸电子有限公司成立于2016年，位于广州市科学城基地的生产力促进中心。微林软件是一家专注于视觉检测和视觉工业自动化的高新企业，通过CV、DL等各种技术，努力打造视觉引导工业自动化的人工智能，极大促进生产力的发展。<br />
          微林软件高度重视自主创新，自公司成立以来，先后研发了具有自主知识产权的三维立体测距、手掌检测、二维码快速识别、视觉高精度测量（尺寸测量、计数、缺陷检测）等相关核心技术。并成功开发了具有自主软件著作权的服务端设备监控、平台化设备监控、机械臂控制、工业设备视觉检测、设备监控等系统检测软件。为客户研制出的高精度视觉对位系统、以及高可靠性的自动化检测设备及整体解决方案，同时可供应配套的工业相机、工业镜头、视觉光源及专业图像处置软件等标准产品。还为客户提供项目评价、计划设计、设备调试、技能咨询及训练等一体化的专业服务。<br />
          广州冰眸电子有限公司正处于高速发展时期，我们除了拥有领先水平的机器视觉商品之外，还能够获得提供的全方位的服务与支持，然后进一步为客户为客户提供全方位的协助。如果是接受过技能训练的直销人员，不仅仅只能处置疑难的的实践使用问题，还能随时处理与我们商品有关的任何技能疑问。公司全体员工将秉承＂炼就火眼金睛，成就智能机器＂的使命，朝着＂2025年，成为视觉领域的知名企业＂这一伟大愿景而迈进！ -->
           <div style="padding-top:20px">冰眸助你解决食物屯积麻烦</div>
           <div>“冰眸”采用了物联网技术、云端服务器。任何环境下都可以通过APP及小程序了解冰箱食物存储情况，根据用户需求随时随地购买补充食物；系统会及时发送食物逾期信息提醒用户及时使用或处理食材</div>
          <div style="padding-top:20px">冰箱食物进出时间管理</div>
          <div>提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能</div>
          <div style="padding-top:20px">食物推荐及小区商铺购物</div>
          <div>根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据APP上显示的食材存量及时补充食物，享受购物便捷的服务</div>
          <div style="padding-top:20px">参考食物制作、分享烹饪心得</div>
          <div>查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人</div>
          <div style="padding-top:20px">冰箱管理、成员设置简单易用</div>
          <div>轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全</div>
        </div>
      </div>
    </div>
    <div class="aboutUs_course">
      <div>
        <div class="homeScenes_title">
          <div>发展历程</div>
          <div>development history</div>
        </div>
        <div class="historyRecord">
          <!-- 日期侧边栏 -->
          <div class="dateRecord">
            <div
              v-for="(item, index) in historyRecord"
              :key="index"
              @click="handleCurrentChange(index, item.list)"
            >
              <div :class="selectCurrent == index ? 'dateRecord_active' : ''">
                {{ item.time }}
              </div>
              <div
                :class="
                  selectCurrent == index
                    ? 'dateRecord_dianActive'
                    : 'dateRecord_dian'
                "
              ></div>
            </div>
          </div>
          <!-- 历程内容 -->
          <div class="historyContent">
            <van-swipe
              @change="handleSwipeChange"
              style="height: 150px"
              vertical
              duration="500"
              ref="vSwipe"
              :loop="false"
              :show-indicators="false"
              :initial-swipe="selectCurrent"
            >
              <van-swipe-item
                v-for="(item, index) in historyRecord"
                :key="index"
              >
                <div class="historyContent_list">
                  <div
                    class="historyContent_item"
                    v-for="(node, idx) in item.list"
                    :key="idx"
                  >
                    {{ node }}
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
    </div>
    <div class="homeLink">
      <div>
        <div class="homeScenes_title">
          <div>友情链接</div>
          <div>friendly link</div>
        </div>
        <div class="homeLink_link">
          <div @click="goLink('http://www.vlimsoft.com/')">
            <img src="@/assets/img/home/link-vlimsoft.png" />
          </div>
          <div
            @click="
              goLink(
                'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
              )
            "
          >
            <img src="@/assets/img/home/link-tbdp.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 历史记录
      historyRecord: [
        {
          time: "2021",
          list: [
            // "2021年12月广州微林股份有限公司成立！",
            "2021年08月冰眸小程序完成升级，APP在各大应用市场上架",
            "2021年04月冰眸二期改版完成，项目整体规划完成",
          ],
        },
        {
          time: "2020",
          list: [
            "2020年10月冰眸项目核心算法模型完成，申请6项专利",
            "2020年04月视觉检测产品被认定为广东省高新科技产品",
          ],
        },
        {
          time: "2019",
          list: [
            "2019年12月获认证为广东省第一批国家高新科技企业",
            "2019年05月完成视觉检测项目，机械臂引导控制的研发",
          ],
        },
        {
          time: "2018",
          list: ["2018年02月获得国家专利，知识产权管理体系认证"],
        },
        {
          time: "2017",
          list: [
            "2017年01月，开始涉足视觉检测行业",
          ],
        },
      ],
      // 选中当前
      selectCurrent: 0,
    };
  },
  created() {
    this.courseContent = this.historyRecord[0].list;
  },
  methods: {
    // 跳转外网
    goLink(url) {
      window.open(url, "_blank");
    },
    // 处理当前日期选中
    handleCurrentChange(index) {
      this.$refs.vSwipe.swipeTo(index);
    },
    handleSwipeChange(index) {
      this.$nextTick(() => {
        this.selectCurrent = index;
      });
    },
  },
};
</script>

<style scoped>
.aboutUs_banner {
  width: 100%;
  height: 136px;
}
.aboutUs_banner img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 介绍 */
.aboutUs_int {
  width: 100%;
  padding: 40px 14px;
  box-sizing: border-box;
  position: relative;
  background-image: url("../assets/img/AboutUs/bg_introduction.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.AboutIntroduction_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* 标题 */
.companyName {
  font-size: 24px;
  color: #47b0b4;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
.companyEnglishName {
  font-size: 13px;
  color: #47b0b4;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
}
/* 图片 */
.AboutIntroduction_img {
  width: 100%;
  height: 260px;
  /* margin-top: 30px; */
}
.AboutIntroduction_img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 内容 */
.companyInt {
  font-size: 16px;
  color: #999999;
  margin-top: 20px;
}
/* 历程 */
.aboutUs_course {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
}
/* 发展历程内容 */
.historyRecord {
  width: 100%;
  height: 150px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;
}
.dateRecord {
  width: 25%;
  height: 100%;
  color: #ccc;
  display: flex;
  font-size: 13px;
  transition: all 0.5s;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.dateRecord > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 6px 0;
  cursor: pointer;
}
.dateRecord_dian {
  right: -3px;
  width: 6px;
  height: 6px;
  margin-left: 20px;
  position: relative;
  transition: all 0.5s;
  border-radius: 100%;
  background-color: #cccccc;
}
.dateRecord_active {
  font-size: 20px;
  font-weight: 500;
  color: transparent;
  background: linear-gradient(to right, #64dfc9, #47b0b4);
  background-clip: text;
}
.dateRecord_dianActive {
  width: 10px;
  height: 10px;
  right: -5px;
  margin-left: 20px;
  position: relative;
  border-radius: 100%;
  transition: all 0.5s;
  background: linear-gradient(to right, #64dfc9 0%, #47b0b4 100%);
}

/* 历程内容 */
.historyContent {
  width: 65%;
  height: 100%;
}
.historyContent_list {
  overflow: scroll;
  height: 100%;
}
.historyContent_item {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-top: 20px;
}
/* 友情链接 */
.homeLink {
  padding: 40px 18px;
  background-color: #f5f5f5;
}
.homeLink_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homeLink_link > div {
  width: calc(50% - 10px);
  height: 68px;
  margin-bottom: 20px;
  box-sizing: border-box;
  /* border: 1px solid #b3b3b3; */
}
.homeLink_link img {
  display: flex;
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
}
/* 页内公共标题 */
.homeScenes_title {
  margin-bottom: 20px;
  text-align: center;
}
.homeScenes_title > div:nth-of-type(1) {
  color: #47b0b4;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.homeScenes_title > div:nth-of-type(2) {
  color: #47b0b4;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}
</style>